<template>
	<div class="ProfileColorCalendar" style="display: flex; gap: 10px;">
		<!-- TODO: manage profile selection with disclosure list -->
		<!-- TODO: autoExpand function -->
		<div style="flex: 1;">
			<DraggableDisclosureList ref="disclosureList" v-model="model"
				:startExpanded="startExpanded"
				style="flex: 1;"
				@select="selectProfile($event)"
				:itemVisible="itemVisible"
			>
				<template #header>
					<slot name="header" />
				</template>
				<template #item-header="{ item: profile }">
					<v-icon style="margin: 6px; font-size: 12pt; color: red;" v-if="profile.isRemove">mdi-cancel</v-icon>
					<ColorPicker v-model="profile.color" v-if="!profile.isRemove" />
					<slot name="item-header" :profile="profile" />
				</template>
				<template #item-body="{ item: profile }">
					<slot name="item-body" :profile="profile" />
				</template>
				<template #selected-item-hint>
					<div class="hint" v-if="$slots['selected-item-hint']">
						<slot name="selected-item-hint" :profile="profile" />
					</div>
				</template>
			</DraggableDisclosureList>
			<div class="validation">
				<slot name="validation" />
			</div>
		</div>
		<div>
			<ColorCalendar
				class="calendar"
				ref="calendar"
				:calendar="calendar"
				:dates="dates"
				:profiles="model"
				:paintingProfile="selectedProfile"
				:focusStart="focusStart"
				:focusEnd="focusEnd"
				@focusRangeChanged="focusStart = $event.focusStart; focusEnd = $event.focusEnd"
				@painted="paint($event.profile, $event.days)"
				@mouseoverDay="(e, d) => $emit('mouseoverDay', e, d)"
				@mouseoutDay="(e, d) => $emit('mouseoutDay', e, d)"
			>
				<template #day-hover="{ day }" v-if="$slots[ 'day-hover' ]">
					<slot name="day-hover" :day="day" />
				</template>
			</ColorCalendar>
		</div>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import ColorCalendar from './ColorCalendar.vue'
import ColorPicker from './ColorPicker.vue'
import DraggableDisclosureList from './DraggableDisclosureList.vue'

// TODO: on delete profile: remove that id from the data
//       -> needs event instead of direct splice

export const calendarCalculatorMixin = {
	data: () => ({
		focusStart: moment().startOf('month'),
		focusEnd: moment().endOf('month'),
		// !multiple: { 2024: { 10: { 30: 1 } } }
		// multiple: { 2024: { 10: { 30: [ 1, 2 ] } } }
		profileIds: {},
	}),
	computed: {
		// TODO: move some common concerns of these functions into exports of ColorCalendar
		dates() {
			// TODO
			// find min and max date
			let minDate = '2023-01-01'
			let maxDate = '2024-12-31'
			const todayDate = moment().startOf('day')
			const today = todayDate.format('YYYY-MM-DD')
			// always include today in the range
			if (today < minDate) minDate = today
			if (today > maxDate) maxDate = today

			const fromDate = moment(minDate).startOf('day').startOf('month')
			const toDate = moment(maxDate).endOf('day').endOf('month')
			const fromMonday = fromDate.clone().startOf('isoWeek')
			const toSunday = toDate.clone().endOf('isoWeek')
			const count = toSunday.diff(fromMonday, 'days') + 1
			const offsetToday = todayDate.startOf('month').startOf('isoWeek').diff(fromDate, 'days')
			return { today, offsetToday, minDate, maxDate, fromDate, toDate, fromMonday, toSunday, count }
		},
		calendar() {
			const days = []
			let d = -1
			for (let currentDay = this.focusStart.clone().startOf('isoWeek');
				currentDay <= this.focusEnd.clone().endOf('isoWeek');
				currentDay = currentDay.add(1, 'days')
			) {
				d++
				const day: { day?: any, year?: string, month?: string, dom?: string, [ i: string ]: string | boolean | number } = { day: currentDay.clone()}
				day.weekday = day.day.format('dddd').toLowerCase()
				day.date = day.day.format('YYYY-MM-DD')
				day.dateObject = day.day.toDate()
				day.year = day.day.format('YYYY')
				day.month = day.day.format('MM')
				day.dom = day.day.format('DD')
				day.open = false // TODO?
				day.x = d % 7
				day.y = Math.floor(d / 7)
				day.selected = false // this.isSelected(day)
				day.inRange = day.date >= this.dates.minDate && day.date <= this.dates.maxDate
				let profileIds = this.profileIds[day.year]?.[day.month]?.[day.dom]
				if (this.multiple) {
					if (this.calcProfiles)
						profileIds = this.calcProfiles(day)
					day.profiles = profileIds?.map(id => this.profileLookup[id] ?? 0)
				}
				else
					day.profile = this.profileLookup[profileIds ?? 0]
				days.push(day)
			}
			return days
		},
		profileLookup() {
			const r = {}
			for (const profile of this.profiles ?? []) {
				r[profile.id] = profile
			}
			return r
		},
	},
}

export default {
	components: { ColorCalendar, ColorPicker, DraggableDisclosureList },
	mixins: [ calendarCalculatorMixin ],
	props: {
		// profiles
		modelValue: Array,
		multiple: Boolean,
		calcProfiles: Function,
		startExpanded: [ Boolean, Function ],
		itemVisible: Function,
	},
	data: () => ({
		model: null,
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		editingDay: null,
		selectedDay: null,
		dayMenu: false,
		jumpMenu: false,
		jumpSearch: '',
		selectedProfile: null,
		timespanFocus: null, // 'start' | 'end' | 'weekdays' | null
	}),
	computed: {
		profiles() {
			return this.modelValue
		},
	},
	watch: {
		modelValue(n) { this.model = n },
		model(n) { this.$emit('update:modelValue', n) },
	},
	methods: {
		go(day) {
			this.$refs.calendar.go(day)
		},
		selectProfile(profile) {
			if (this.selectedProfile == profile) return
			this.selectedProfile = profile
			this.go(profile.start)
			this.profileFocus = null
		},
		paint(profile, days) {
			for (const day of days) {
				if (!this.profileIds[day.year]) this.profileIds[day.year] = {}
				if (!this.profileIds[day.year][day.month]) this.profileIds[day.year][day.month] = {}
				if (this.multiple) {
					let a = this.profileIds[day.year][day.month][day.dom]
					if (!a) a = []
					this.profileIds[day.year][day.month][day.dom] = a
					// TODO: this is just a test - this is not good enough
					//       we would probably have to allow removing one color with shift?
					if (!a.includes(profile.id))
						a.push(profile.id)
				}
				else
					this.profileIds[day.year][day.month][day.dom] = profile.id
			}
		},
		expandProfile(profile) {
			this.$refs.disclosureList?.expand?.(profile.id)
		},
	},
	beforeMount() {
		this.model = this.modelValue
	},
	mounted() {
		this.go('today')
	},
}
</script>

<style scoped>
.ProfileColorCalendar { --sel: lightskyblue; --focus: #295dc7; }

.profile { border-radius: 5px; position: relative; }
.profile.selected { outline: 2px solid var(--sel); }
table th { border-bottom: 1px solid #ddd; }
table th,
table td { text-align: left; padding: 10px; }
table input { border: 1px solid #ddd; border-radius: 5px; padding: 3px 6px; }

.profile .details { display: flex; gap: 10px; flex-wrap: wrap; }
.hint { background: var(--sel); padding: 0px 5px; position: absolute; bottom: calc(-0.5em - 3px); right: 10px; font-size: 11px; color: white; border-radius: 10px; }

.calendar { position: sticky; top: 120px; }
</style>

<style>
.PCC .v-color-picker-preview__dot,
.PCC .v-color-picker-preview__eye-dropper { display: none; }
</style>
<template>
	<Disclosure
		:title="$t('text.seasonalPricing')"
		:error="sectionMessage.error"
		:message="sectionMessage.message"
		data-cy="ticketInfo"
		ref="SECTION_seasonalPricing"
		v-if="modelValue"
	>
		<template #header-accessory>
			<div style="flex: 1;" />
			<ProFeatureTag />
		</template>
		<div v-if="!ticketsDefined">
			{{ $t('text.seasonalPricingTicketsMissing') }}
		</div>
		<div v-else>
			<!-- TODO: show editable price matrix for default prices?
						probably not easily possible to make reactive with Ticket.. -->
			<Field :title="$t('text.seasonalPricing')" :infoText="$t('text.seasonalPricing_info')" />
			<PriceMatrixCalendar v-model="modelValue.de" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" />
			<Field :title="$t('text.seasonalPricingExceptions')" :infoText="$t('text.seasonalPricingExceptions_info')" />
			<PriceMatrixCalendar v-model="modelValue.de" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" mode="exception" />
		</div>
	</Disclosure>
</template>

<script lang="ts">
import Common from '../../../mixins/Common.vue'
import Disclosure from '../../../components/common/Disclosure.vue'
import PriceMatrixCalendar from '../packageDesigner/PriceMatrixCalendar.vue'
import ProFeatureTag from '../packageDesigner/ProFeatureTag.vue'
import Field from '../../../components/fields/Field.vue'

export default {
	components: { Disclosure, PriceMatrixCalendar, ProFeatureTag, Field },
	mixins: [ Common ],
	props: {
		product: Object,
		serviceProvider: Object,
		updateModel: Boolean,
		productIsExternal: Boolean,
		modelValue: Object, // { de: PricingTimespan[] }
	},
	data: () => ({
		sectionMessage: {
			error: false,
			message: ''
		},
		profiles: [
			// prices: { adult_1: 0, child_1: 0, senior_1: 0, adult_2: 0, child_2: 0, senior_2: 0 }
			//{ id: 0, color: null, name: 'default', weekdays: [], prices: {}, isRemove: true },
			{ id: 1, color: '#aae4fa', name: 'summer', weekdays: [], prices: [], start: '2024-10-01', end: '2024-10-31' },
			{ id: 2, color: '#cbedb6', name: 'winter', weekdays: [], prices: [], start: null, end: null },
			{ id: 3, color: '#ffe6aa', name: 'holiday', weekdays: [], prices: [], start: null, end: null },
		],
	}),
	computed: {
		// merge ticketTypes and ticketCategories from client and serviceProvider
		// this is essentially what the user can select from
		// gives the complete objects (with .fields)
		allTicketTypes() {
			return [
				...this.$store.state.selectedServiceProvider.fields.ticketTypes.de,
				...this.$store.state.selectedClient.fields.ticketTypes.de,
			]
		},
		allTicketCategories() {
			return [
				...this.$store.state.selectedServiceProvider.fields.reductions.de,
				...this.$store.state.selectedClient.fields.reductions.de,
			]
		},
		// ticket variations of the product (only contains links to the actual ticketType and reduction)
		ticketOptions() {
			const ticket = this.product?.fields?.ticket?.de
			return ticket?.fields?.ticketOptions?.de ?? []
		},
		// full objects that are linked in the ticketOptions
		ticketTypes() {
			const ids = this.ticketOptions.map((option: any) => option.fields?.ticketType?.de?.sys?.id)
			return this.allTicketTypes.filter((o: any) => ids.includes(o.sys.id))
		},
		ticketCategories() {
			const ids = this.ticketOptions.map((option: any) => option.fields?.reduction?.de?.sys?.id)
			return this.allTicketCategories.filter((o: any) => ids.includes(o.sys.id))
		},
		ticketsDefined() {
			return this.ticketTypes.length > 0 && this.ticketCategories.length > 0
		},
		basePrices() {
			return this.ticketOptions.map((option: any) => {
				const ticketType = option.fields?.ticketType?.de
				const ticketCategory = option.fields?.reduction?.de
				return {
					ticketType: ticketType?.sys?.id,
					ticketCategory: ticketCategory?.sys?.id,
					price: option.fields.price.de,
				}
			})
		},
	},
	mounted() {
		// repair potentially missing seasonalPricing prop
		if (!this.modelValue) {
			this.$emit('update:modelValue', { de: [] })
		}
	},
}
</script>

<style scoped>
</style>